body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.padding-1{
  padding: 1.5%;
}

.btn-main{
  background-color: #12336E !important;
  border-radius: 40px !important;
  width: 120px;
  color: white !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 40px;
}

.width-5{
  width: 50%;
}

.fw-6{
  font-weight: 600;
}

.referralFormDesc{
  color: #5A6475;
  font-size: 13px;
  margin-top: 2px;
}

.border-radius-2 div {
  border-radius: 10px !important;
}